import React from 'react';
import Layout from '../components/layout';
import SEO from '../components/seo';
import Catalog from '../components/catalog';

const Apps = ({ pageContext }) => {
    const { apps, categories } = pageContext;

    return (
        <Layout>
            <SEO title="Applications" />
            <Catalog apps={apps} categories={categories} />
        </Layout>
    );
};

export default Apps;
