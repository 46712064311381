import React, { useState } from 'react';
import { Link } from 'gatsby';

export default ({ apps, categories: allCategories }) => {
    const DEFAULT_CATEGORY = 'Toutes les catégories';
    const FREE_CATEGORY = 'Gratuit';

    const [applications] = useState(apps);
    const [categories] = useState(allCategories);
    const [selectedCategory, selectCategory] = useState(DEFAULT_CATEGORY);

    const totalApplications = applications.filter((app) => !app.categories.includes(FREE_CATEGORY)).length;

    return (
        <section className="py-10 pt-28">
            <div className="max-w-screen-xl mx-auto px-4 sm:px-6 lg:px-8">
                <h3 className="text-center mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl sm:leading-10">
                    Choisissez une application
                </h3>

                <p className="text-center mt-4 max-w-2xl text-xl leading-7 text-gray-500 lg:mx-auto">
                    Obtenez un accès à {totalApplications} applications open-source à installer en 1 clic
                </p>

                <div className="py-10 text-center">
                    { categories.map((category) => {
                        return (
                            <span key={category}>
                                { selectedCategory === category ? (
                                    <button type="button" className="ml-2 mt-2 inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs leading-4 font-medium rounded text-white bg-gray-600 hover:bg-gray-500 focus:outline-none focus:border-gray-700 active:bg-gray-700 transition ease-in-out duration-150">
                                        {category}
                                        {category === FREE_CATEGORY && <span className="ml-1">🎁</span>}
                                    </button>
                                ) : (
                                    <button type="button" onClick={() => selectCategory(category)} className="ml-2 mt-2 inline-flex items-center px-2.5 py-1.5 border border-gray-300 text-xs leading-4 font-medium rounded text-gray-700 bg-white hover:text-gray-500 focus:outline-none active:text-gray-800 active:bg-gray-50 transition ease-in-out duration-150">
                                        {category}
                                        {category === FREE_CATEGORY && <span className="ml-1">🎁</span>}
                                    </button>
                                ) }
                            </span>
                        );
                    }) }
                </div>

                <div className="grid gap-3 sm:grid-cols-2 sm:gap-3 lg:grid-cols-3 lg:gap-3 xl:grid-cols-4 xl:gap-4 mt-10">
                    { applications.filter((app) => {
                        if (selectedCategory === FREE_CATEGORY) return app.categories.includes(FREE_CATEGORY);

                        if (selectedCategory !== DEFAULT_CATEGORY) {
                            return (app.categories.includes(selectedCategory) && !app.categories.includes(FREE_CATEGORY));
                        }

                        return !app.categories.includes(FREE_CATEGORY);
                    }).map(({ name, description, categories: c, logo, website }) => {
                        return (
                            <div className="bg-white overflow-hidden shadow rounded-lg" key={name}>
                                <div className="px-4 py-5 sm:p-6 lg:p-4">
                                    <div className="flex justify-between">
                                        <div>
                                            <h1 className="font-bold">{name}</h1>
                                            <h2 className="capitalize text-sm">{c[0]}</h2>
                                        </div>
                                        <img src={logo} alt="logo" className="w-14 h-14" />
                                    </div>

                                    { c.includes(FREE_CATEGORY) && (
                                        <p className="text-sm text-gray-700 mt-2">{description}</p>
                                    ) }

                                    <div className="mt-8 border-t border-gray-200 pt-5">
                                        <Link
                                            to={c.includes(FREE_CATEGORY) ? website : `/${name.toLowerCase()}`}
                                            type="button"
                                            className="inline-flex justify-center w-full rounded-md border border-gray-300 px-4 py-2 bg-white text-base leading-6 font-medium text-gray-700 shadow-sm hover:text-gray-500 focus:outline-none transition ease-in-out duration-150 sm:text-sm sm:leading-5"
                                        >
                                            En savoir plus
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        );
                    }) }
                </div>
            </div>
        </section>
    );
};
